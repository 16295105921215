import { run } from '../utils';

run('.flotr2-documentation', function () {
  var
    Editor = Flotr.Examples.Editor;

  $.get('example', function (response) {
    var
      usageExample = $('.editor.usage');

    new Editor($('.editor.usage'), {
      example : response,
      type : 'html'
    });

    $.get('/lib/DefaultOptions.js', function (response) {
      new Editor($('.editor.api'), {
        noRun : true,
        example : response
      });
    });
  });
});
