function run (selector, callback) {
  var $container = $(selector);
  if ($container.length) {
    $(() => callback($container));
  }
}

export {
  run
};
